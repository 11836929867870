.fullscreen-hero-arts {
    background-image: url('/img/projects/verde-arts-precinct/hero.jpg');
}

.fullscreen-hero-domain-house {
    background-image: url('/img/projects/domain-house/hero.jpg');
}

.fullscreen-hero-domain-house2 {
  background-image: url('/img/projects/domain-house/hero2.jpg');
}

.fullscreen-hero-residence-east {
    background-image: url('/img/projects/residence-east/hero.jpg');
}

.no-blur {
  filter: blur(0);
}

.fullscreen-heros {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: 1s ease all;
    pointer-events: none;
    touch-action: none;
    opacity: 0;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    z-index: 0;
}
.showBg {
  opacity:0.4;
}