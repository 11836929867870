@labelCol: #000;
@inputCol: #000;
@backgroundCol: transparent;
@formFontSize: 1.2em;

.input-default {
  .sans;
  appearance: none;
  outline: none;
  border-radius: 0px;

  border: none;
  background-color: transparent;
  font-size: 2rem;
  color: @white;
  width: 100%;
}
.submit-default {
  font-size: 1.4rem;
  color: @white;
  border: none;
  background-color: transparent;
  text-decoration: underline;
}

.text-input,
.custom-select,
.custom-submit,
.custom-textarea {
  .input-default;
  display:block;
  width:100%;
  height: 35px;
  padding: 0;
}

.custom-select {
  height: 38px;
  padding-right: 30px;
  color: @inputCol;
  background: url("../img/core/arrow.svg") right center no-repeat;
}

.custom-textarea {
  display:block;
  height: 100px;
  border:1px solid #000;
}

.custom-submit {
  .submit-default;
  text-align: left;
}

label {
  display:block;
  color: @labelCol;
  font-weight: bold;
}

::placeholder {
  color: #767676;
}

.error {
  color: red !important;
}

.subscribe-input {
  .input-default;
}
.subscribe-button {
  .submit-default;
}

.checkbox-wrapper-43 input[type="checkbox"] {
  display: none;
  visibility: hidden;
}

.checkbox-wrapper-43 label {
  display: inline-block;
}

.checkbox-wrapper-43 .check {
  cursor: pointer;
  position: relative;
  margin: auto;
  width: 18px;
  height: 18px;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-43 .check:before {
  content: "";
  position: absolute;
  top: -15px;
  left: -15px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(34,50,84,0.03);
  opacity: 0;
  transition: opacity 0.2s ease;
}
.checkbox-wrapper-43 .check svg {
  position: relative;
  z-index: 1;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #c8ccd4;
  stroke-width: 1.5;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
}
.checkbox-wrapper-43 .check svg path {
  stroke-dasharray: 60;
  stroke-dashoffset: 0;
}
.checkbox-wrapper-43 .check svg polyline {
  stroke-dasharray: 22;
  stroke-dashoffset: 66;
}
.checkbox-wrapper-43 .check:hover:before {
  opacity: 1;
}
.checkbox-wrapper-43 .check:hover svg {
  stroke: #ffffff;
}
.checkbox-wrapper-43 input[type="checkbox"]:checked + .check svg {
  stroke: #ffffff;
}
.checkbox-wrapper-43 input[type="checkbox"]:checked + .check svg path {
  stroke-dashoffset: 60;
  transition: all 0.3s linear;
}
.checkbox-wrapper-43 input[type="checkbox"]:checked + .check svg polyline {
  stroke-dashoffset: 42;
  transition: all 0.2s linear;
  transition-delay: 0.15s;
}

.error svg {
  stroke: red !important;
}

// md styles
@media (max-width: 1199px) {}

// sm styles
@media (max-width: 991px) {}

// xs styles
@media (max-width: 767px) {}