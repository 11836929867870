.archive-image {
  transition: 1s ease all;
  max-height: 0px;
  overflow: hidden;
}

.archive-image-active {
  max-height: 100vh;
}


.archive-project-mob {
  svg {
    transition: 0.4s ease all;
  }
}

.archive-project-mob__active {
  svg {
    transform: rotate(90deg);
  }
}

.archive-wrapper {
  margin-bottom: 250px;
}

// sm styles
@media (max-width: 991px) {

  .archive-wrapper {
    margin-bottom: 150px;
  }

}
