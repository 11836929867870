@expand-column-transition: 1s ease all;
@expand-column-background-color: #2c3840;
@expand-column-hover-width: 70%;
@expand-column-fluid: true;
@projectHeight: 60vh;
.expand-column-wrapper {
  height: @projectHeight;

  // max-width: none;
  max-width: 100%;
  &:hover {
    .column {
      flex-basis: 15%;
      opacity: 0.25;
      .expand-column-content {
        opacity: 0;
      }
    }
  }
  .column {
    padding: 1rem;
    transition: @expand-column-transition;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: @projectHeight;
    flex-basis: 33.3333333%;
    position: relative;
    &:hover {
      flex-basis: @expand-column-hover-width;
      opacity: 1;
    }
    &:hover {
      .expand-column-content {
        opacity: 1;

      }
    }
    &:first-of-type {
      border-right: 10px solid @white;
    }
    &:last-of-type {
      border-left: 10px solid @white;
    }
  }

  .expand-column-content {
    color: @black;
    transition: @expand-column-transition;
    position: absolute;
    bottom: -80px;
    left: 0;
  }


}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
  .expand-column-wrapper {
    flex-direction: column;
  }
  .project-arts__mob {
    background-image: url('/img/projects/verde-arts-precinct/project-selector-2.jpg');
    background-position: center right;
  }
  .project-domain-house__mob {
    background-image: url('/img/projects/domain-house/project-selector__mob.jpg');
  }
  .project-residence-east__mob {
    background-image: url('/img/projects/residence-east/project-selector__mob.jpg');
  }
}

// xs styles
@media (max-width: 767px) {

}



.expand-column-wrapper .column {
  &:nth-of-type(1) {
    background-image: url('/img/projects/verde-arts-precinct/project-selector-2.jpg');
    background-size: cover;
    background-position: center;
    // margin-right: 2rem;
  }

  &:nth-of-type(2) {
    background-image: url('/img/projects/domain-house/project-selector.jpg');
    background-size: cover;
    background-position: center;
    // margin-right: 2rem;

  }

  &:nth-of-type(3) {
    background-image: url('/img/projects/residence-east/project-selector.jpg');
    background-size: cover;
    background-position: center;

  }


}

