.menu-logo {
  width: 420px;
  svg {
    width: 100%;
    height: auto;
    transition: 1s ease all;
  }
  &:hover {
    #logo-considered {
      opacity: 1;
    }
  }
}

#logo-considered {
  transition: 1s ease all;
  opacity: 0;
}

.menu-link {
  color: @black;
  &:hover {
    color: @grey__900;
  }
}

.white-menu {
  .menu-link {
    color: @white;
  }
  &:hover {
    color: @white;
  }
  .svg-logo,
  #logo-considered,
  .svg-logo-mob,
  .svg-burger {
    fill: @white;
  }
  .anim-line {
    background-color: @white;
  }
}

.menu {
	list-style: none;
	padding: 0;
	margin: 10px 0;
	text-align: center;
	li {
		display: inline-block;
		list-style: none;
    padding: 0;
		margin-right: 100px;
		a {
			transition: 0.5s ease;
			text-decoration: none;
			&:hover {
				transition: 0.5s ease;
		 		text-decoration: underline;
			}
		}
		&:last-child {
			margin-right: 0px;
		}
	}
}



// md styles
@media (max-width: 1199px) {
  .menu {
    li {
      margin-right: 70px;
    }
  }
}

// sm styles
@media (max-width: 991px) {
  #logo-considered,
  #logo-pg {
    display: none;
  }
  .mobile-menu {
    position: fixed;
    top: -140vh;
    left: 0;
    width: 100%;
    z-index: 99;
    transition: 1s ease all;
    #logo-virgate {
      fill: @white;
    }
    .menu-link {
      font-size: 4vh;
      color: @white;
    }
  }

  .enquire {
    .svg-burger {
      fill: @white !important;
    }
  }

  .mobile-menu-active {
    top: 0;
  }
}

// xs styles
@media (max-width: 767px) {

}