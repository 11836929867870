.anim-line {
  background-color: @black;
  height: 1px;
  width: 0;
  transition: 1.5s ease all;
  &.anim-line__animated {
    width: 100%;
  }
}


