.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.swiper-caption {
    .serif;
    position: absolute;
    bottom:0;
    left:0;
    padding: 16px;
    color:#ffffff;
}

.swiper-button-prev,
.swiper-button-next {
    color:#ffffff;
    opacity: 0;
    transition: 0.4s ease all;
}
.swiper {
  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
        color:#ffffff;
        opacity: 1;
    }
  }
}

.swiper-pagination {
  text-align: right;
  padding-right: 20px;
}
.swiper-pagination-bullet-active,
.swiper-pagination-bullet {
    background:#ffffff;
}