.expandable {
  transition: 1s ease all;
  max-height: 0;
  overflow: hidden;
}

.expanded {
  max-height: 2000px;
  overflow: auto;
}

.expand-arrow {
  width: 25px;
  height: 25px;
  display: inline-block;

  transition: 500ms ease all;
  svg {
    width: 100%;
  }
}

.expand-arrow-active {
  .expand-arrow {
    transform: rotate(90deg);
  }
}