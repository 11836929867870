@width: 100%;
@height: 100vh;
@margin: 10vh;
@heightMob: 60vh;
@marginMob: 150px;
// @animationTime: 0.1s; //? For speedier dev time, turn off animation
@animationTime: 0.6s;
@strokeWidth: 1px;

.initiallyOverflowHidden {
  overflow: hidden;
}

.loader {
  position:absolute;
  top:0;
  left:0;
  width:100vw;
  height:@height - @margin;
  transition: @animationTime ease  all;
  pointer-events: none;
}

.initiallyHidden {
  opacity: 0;
  height: 0;
}

.ls {
  position: relative;
  background-color: transparent;
  width: calc(@width );
  height: calc(@height - (@margin * 2));
  margin-top: @margin;
}

.l1,
.l2,
.l3,
.l4 {
  position: absolute;
  background: transparent;
  width: 0px;
  height: 0px;

  animation-duration: @animationTime;
  animation-iteration-count:1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}


.l1 {
  left: 0;
  bottom: 0;
  height: calc(@height - (@margin * 2));
  width: @strokeWidth;
  animation-name: l1;
  animation-delay: 0s;
}

.l2 {
  top: 0;
  left: 0;
  width: calc(@width );
  height: @strokeWidth;
  animation-name: l2;
  animation-delay: @animationTime;
}

.l3 {
  right: 0;
  top: 0;
  height: calc(@height - (@margin * 2));
  width: @strokeWidth;
  animation-name: l3;
  animation-delay: @animationTime * 2;
}
.l4 {
  bottom: 0;
  right: 0;
  width: calc(@width );
  height: @strokeWidth;
  animation-name: l4;
  animation-delay: @animationTime * 3;
}

.logo-wrapper {
  opacity: 0;
  transition: 1s ease all;
  width: 8vw;
  svg {
    width: 100%;
    height: auto;
  }
}
.logo-wrapper-visibile {
  opacity: 1;
}
@keyframes l1 {
  0%    {
      height: 0;
      background: #000;
  }
  100%  {
    height: calc(@height - (@margin * 2));
    background: #000;
  }
}

@keyframes l2 {
  0%    {
    width: 0;
    background: #000;
  }
  100%  {
    width: calc(@width );
    background: #000;
  }
}

@keyframes l3 {
  0%    {
    height: 0;
    background: #000;
  }
  100%  {
    height: calc(@height - (@margin * 2));
    background: #000;
  }
}

@keyframes l4 {
  0%    {
    width: 0;
    background: #000;
  }
  100%  {
    width: calc(@width );
    background: #000;
  }
}

@keyframes l4 {
  0%    {
    width: 0;
    background: #000;
  }
  100%  {
    width: calc(@width );
    background: #000;
  }
}


// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
  .logo-wrapper {
    width: 20vw;
  }
  .ls {
    margin-top: @marginMob;
    height: calc(@heightMob - (@marginMob ));
  }
  .l1, .l3 {
    height: calc(@heightMob - (@marginMob ));
  }


  @keyframes l1 {
    0%    {
      height: 0;
      background: #000;
    }
    100%  {
      height: calc(@heightMob - (@marginMob ));
      background: #000;
    }
  }

  @keyframes l3 {
    0%    {
      height: 0;
      background: #000;
    }
    100%  {
      height: calc(@heightMob - (@marginMob ));
      background: #000;
    }
  }

}

// xs styles
@media (max-width: 767px) {

}