@import (less) "normalize.css";

@import (less) "bootstrap-custom";

@import (less) "../node_modules/swiper/swiper-bundle.css";

@import (less) "../recaptcha/recaptcha.css";

@import (less) "savi-core.less";
@import (less) "fonts.less";
@import (less) "variables.less";
@import (less) "helpers.less";
@import (less) "menu.less";
@import (less) "slider.less";
@import (less) "form.less";
@import (less) "thankyou.less";


@import (less) "loader.less";
@import (less) "home.less";
@import (less) "hero.less";

@import (less) "fullscreen-hero.less";
@import (less) "expandable.less";

@import (less) "about.less";
@import (less) "collaborators.less";

@import (less) "hover-projects.less";
@import (less) "archive-projects.less";
@import (less) "full-width-project.less";
@import (less) "bottom-content.less";
@import (less) "dark-page.less";

@import (less) "animated-line.less";
@import (less) "swiper.less";

.page-wrapper {
  background-color: #ffffff;
}
