.enquire,
.privacy-policy,
.thankyou,
.thankyou-subscribe {
  background-color: @black__400;
  .menu-wrapper {
    background-color: @black__400;
  }
  .menu-link {
    color: @white;
    &:hover {
      color: @white;
    }
  }

  .bottom-container {
    background-color: @black__400;
  }
  .bottom-content {
    p, a {
      color: @white;
    }
  }
  .border-style {
    border-top: 1px solid @white;
  }
  .svg-logo,
  #logo-considered,
  .svg-logo-mob {
    fill: @white;
  }
  .anim-line {
    background-color: @white;
  }
}

