@import 'for';
@import 'variables';

//? Colours and backgrounds
.for(@colours); .-each(@value) {
  @name:  extract(@value, 1);
  @colour: extract(@value, 2);
  .col-@{name} {
      color: @colour;
  }
  .hover-@{name} {
    &:hover {
      color: @colour;
    }
  }
  .bg-@{name} {
    background-color: @colour;
  }
  .bghover-@{name} {
    &:hover {
      background-color: @colour;
    }
  }
  .b-1-@{name} {
    border: 1px solid @colour;
  }
  .bb-1-@{name} {
    border-bottom: 1px solid @colour;
  }
  .bt-1-@{name} {
    border-top: 1px solid @colour;
  }
  .bl-1-@{name} {
    border-left: 1px solid @colour;
  }
  .br-1-@{name} {
    border-right: 1px solid @colour;
  }
  .border-@{name} {
    border-color: @colour;
  }
  @media (max-width: 991px) {
    .col-@{name}__mob {
      color: @colour;
    }
    .hover-@{name}__mob {
      &:hover {
        color: @colour;
      }
    }
    .bg-@{name}__mob {
      background-color: @colour;
    }
    .bghover-@{name}__mob {
      &:hover {
        background-color: @colour;
      }
    }
    .b-1-@{name}__mob {
      border: 1px solid @colour;
    }
    .bb-1-@{name}__mob {
      border-bottom: 1px solid @colour;
    }
    .bt-1-@{name}__mob {
      border-top: 1px solid @colour;
    }
    .bl-1-@{name}__mob {
      border-left: 1px solid @colour;
    }
    .br-1-@{name}__mob {
      border-right: 1px solid @colour;
    }
  }
};

.for(@sizes); .-each(@value) {
  .mh-@{value}-vh {
    min-height: ~"@{value}vh";
  }
  .mxh-@{value}-vh {
    max-height: ~"@{value}vh";
  }
  .h-@{value}-vh {
    height: ~"@{value}vh";
  }
  .h-@{value}-p {
    height: ~"@{value}%";
  }
  .mh-@{value}-p {
    min-height:  ~"@{value}%";
  }
  .mw-@{value}-vw {
    min-width: ~"@{value}vw";
  }
  .w-@{value}-vw {
    width: ~"@{value}vw";
  }
  .mw-@{value}-p {
    min-width:  ~"@{value}%";
  }
  .w-@{value}-p {
    width:  ~"@{value}%";
  }
  .z-@{value} {
    z-index: @value;
  }

  @media (max-width: 991px) {
    .mh-@{value}-vh__mob {
      min-height: ~"@{value}vh !important";
    }
    .h-@{value}-vh__mob {
      height: ~"@{value}vh !important";
    }
    .h-@{value}-p__mob {
      height: ~"@{value}% !important";
    }
    .mh-@{value}-p__mob {
      min-height:  ~"@{value}% !important";
    }
    .mw-@{value}-vw__mob {
      min-width: ~"@{value}vw !important";
    }
    .mxw-@{value}-vw__mob {
      max-width: ~"@{value}vw !important";
    }
    .w-@{value}-vw__mob {
      width: ~"@{value}vw !important";
    }
    .mw-@{value}-p__mob {
      min-width:  ~"@{value}% !important";
    }
    .w-@{value}-p__mob {
      width:  ~"@{value}% !important";
    }
  }
};

.text-underline {
  text-decoration: underline;
}


//? background
.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.bg-tl {
  background-position: top left;
}
.bg-tc {
  background-position: top center;
}
.bg-tr {
  background-position: top right;
}

.bg-cl {
  background-position: center left;
}
.bg-cc {
  background-position: center center;
}
.bg-cr {
  background-position: center right;
}

.bg-bl {
  background-position: bottom left;
}
.bg-bc {
  background-position: bottom center;
}
.bg-br {
  background-position: bottom right;
}

.tr-1s-ease-all {
  transition: 1s ease all;
}
.transition-default {
  transition: @transition;
}
.projectBlurFadeIn {
  transition: 2.3s ease all;
}

.postion-relative {
  position: relative;
}

.line-height-0-9 {
  line-height: 0.9;
}

.svg-w-100 {
  svg {
    width: 100%;
    height: auto;
  }
}

.lh-1p3 {
  line-height: 1.3;
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {

  .mh-auto__mob {
    min-height: auto !important;
  }
  .b-none__mob {
    border: none;
  }
  .h-auto__mob {
    height: auto;
  }
  .position-fixed__mob {
    position: fixed !important;
  }

}

// xs styles
@media (max-width: 767px) {

}