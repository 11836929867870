.bottom-container {
  background-color: @white;
}
.bottom-content {
  p, a {
    color: @black;
  }
}
.border-style {
  border-top: 1px solid @black;
}