.full-width-project {
  bottom:-40px;
  left:0;
}


// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
  .full-width-project {
    left:20px;
  }


}

// xs styles
@media (max-width: 767px) {

}