.serif {
  font-family: "cordale", serif;
  font-weight: 400;
  font-style: normal;
}

.serif-bold {
  font-family: "cordale", serif;
  font-weight: 700;
  font-style: normal;
}



.sans {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
}
.sans-regular {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}
.sans-medium {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.font-sm {
  font-size: 14px;
}


.font-xl {
  font-size: 50px;
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {

  @font-xxl:45px;
  @font-xl: 40px;
  @font-lg: 21px;
  @font-md: 15px;
  @font-sm: 11px;
  @font-xs: 10px;



  .font-xxl__mob {
    font-size: @font-xxl;
  }

  .font-xl__mob {
    font-size: @font-xl;
  }

  .font-lg__mob {
    font-size: @font-lg;
  }

  .font-md__mob {
    font-size: @font-md;
  }

  .font-sm__mob {
    font-size: @font-sm;
  }

  .font-xs__mob {
    font-size: @font-xs;
  }
}

// xs styles
@media (max-width: 767px) {

}